import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { HTML_ATTRIBUTE_COOKIE_IDS } from ".";
import { OPT_IN_CONTENT_BLOCKER_ALL } from "..";
/**
 * Listen to clicked content blockers and automatically delegate the click
 * event to the underlying element. This is especially useful for overlays of videos
 * so the user does not need to click twice.
 */

function delegateClick(element, _ref) {
  var same = _ref.same,
      nextSibling = _ref.nextSibling,
      parentNextSibling = _ref.parentNextSibling;
  var delegateClick;
  var nextElementSibling = element.nextElementSibling;
  var parentElement = element.parentElement;
  var parentElementNextSibling = parentElement === null || parentElement === void 0 ? void 0 : parentElement.nextElementSibling;

  var _iterator = _createForOfIteratorHelper([[// Same element (= the blocked element)
  element, same], [// Next sibling element to the blocked content
  nextElementSibling, nextSibling], [// Next sibling element of parent of the blocked content
  parentElementNextSibling, parentNextSibling]]),
      _step;

  try {
    loop1: for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _step$value = _slicedToArray(_step.value, 2),
          node = _step$value[0],
          selectors = _step$value[1];

      if (node && selectors) {
        var _iterator2 = _createForOfIteratorHelper(selectors),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var selector = _step2.value;

            if (node.matches(selector)) {
              delegateClick = node;
              break loop1;
            } // Check for children selector


            var childrenSelected = node.querySelector(selector);

            if (childrenSelected) {
              delegateClick = childrenSelected;
              break loop1;
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  } // We delegate the click to an element, let's check if it is also blocked and listen to unblock


  if (delegateClick) {
    var fn = function fn() {
      return setTimeout(function () {
        return delegateClick.click();
      },
      /* Let's delay so we can start after `manipulateDom#customTriggers()` */
      100);
    };

    if (delegateClick.hasAttribute(HTML_ATTRIBUTE_COOKIE_IDS)) {
      delegateClick.addEventListener(OPT_IN_CONTENT_BLOCKER_ALL, fn, {
        once: true
      });
    } else {
      fn();
    }
  }
}

export { delegateClick };
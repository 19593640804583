import { WINDOW_ONLOAD_UNBLOCK_REPLACEMENT } from "@devowl-wp/headless-content-unblocker";
var overwritten = false;
/**
 * Overwrite `window.rcbInitiatorOnload =` assignemnts so they get immediatly executed.
 * At the time of consent the window is definitely loaded.
 */

function applyWindowOnloadInitiator(doc) {
  // Only overwrite once
  if (overwritten) {
    return;
  }

  var w = doc.defaultView || doc.parentWindow;

  try {
    Object.defineProperty(w, WINDOW_ONLOAD_UNBLOCK_REPLACEMENT, {
      set: function set(newValue) {
        if (typeof newValue === "function") {
          newValue();
        }
      },
      enumerable: true,
      configurable: true
    });
  } catch (e) {// Silence is golden
  }

  overwritten = true;
}

export { applyWindowOnloadInitiator };
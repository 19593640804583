/**
 * For a list of unblocked nodes, check if it are a list of `<source`s and load the parent `<video`.
 *
 * @see https://stackoverflow.com/a/18454389/5506547
 */
function loadVideoSource(unblockedNodes) {
  var foundVideos = unblockedNodes.filter(function (_ref) {
    var _ref$node = _ref.node,
        nodeName = _ref$node.nodeName,
        parentElement = _ref$node.parentElement;
    return nodeName === "SOURCE" && parentElement.nodeName === "VIDEO";
  }).map(function (_ref2) {
    var parentElement = _ref2.node.parentElement;
    return parentElement;
  }); // Unique it to avoid multiple load's

  foundVideos.filter(function (value, index) {
    return foundVideos.indexOf(value) === index;
  }).forEach(function (video) {
    return video.load();
  });
}

export { loadVideoSource };
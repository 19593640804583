import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import { putStyleInlineToDom, HTML_ATTRIBUTE_INLINE_STYLE, HTML_TAG_CONSENT_SCRIPT, HTML_ATTRIBUTE_COOKIE_IDS } from ".";
import { decideToUnblock, htmlDecode } from "..";
/**
 * Find consent URLs and check for consent and return new style string.
 *
 * @see https://regex101.com/r/9FJWnR/1
 * @param style
 * @param checker
 */

function transformInlineStyle(style, checker) {
  var noConsentCount = 0;
  var newStyle = style.replace(/(url\s*\(["'\s]*)([^"]+dummy\.(?:png|css))\?consent-required=([0-9,]+)&consent-by=(\w+)&consent-id=(\d+)&consent-original-url=([^-]+)-/gm, function (m, urlPrefix, maskedUrl, requiredString, by, blockerId, base64EncodedUrl) {
    var _decideToUnblock = decideToUnblock(by, requiredString, +blockerId, checker),
        consent = _decideToUnblock.consent;

    if (!consent) {
      noConsentCount++;
    }

    return consent ? "".concat(urlPrefix).concat(htmlDecode(atob(base64EncodedUrl))) : m;
  });
  return [newStyle, noConsentCount];
}
/**
 * Get all blocked rules within a inline CSS style and check, if they can be enabled by a consent.
 */


function transformInlineStyleRules(checker) {
  var nodes; // First: Iterate all `style`'s

  nodes = Array.prototype.slice.call(document.querySelectorAll("[".concat(HTML_ATTRIBUTE_INLINE_STYLE, "]")));

  var _iterator = _createForOfIteratorHelper(nodes),
      _step;

  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var node = _step.value;
      // Get the inline style (can be inside attribute when it should be placed to DOM the first time)
      var isFirstTransition = node.tagName.toLowerCase() === HTML_TAG_CONSENT_SCRIPT;
      var innerHTML = isFirstTransition ? node.getAttribute(HTML_ATTRIBUTE_INLINE_STYLE) : node.innerHTML;

      var _transformInlineStyle = transformInlineStyle(innerHTML, checker),
          _transformInlineStyle2 = _slicedToArray(_transformInlineStyle, 2),
          newInnerHTML = _transformInlineStyle2[0],
          noConsentCount = _transformInlineStyle2[1]; // Put pack to DOM


      if (isFirstTransition) {
        node.setAttribute(HTML_ATTRIBUTE_INLINE_STYLE, newInnerHTML);
        putStyleInlineToDom(node);
      } else {
        if (node.innerHTML !== newInnerHTML) {
          node.innerHTML = newInnerHTML;
        }

        if (noConsentCount === 0) {
          node.removeAttribute(HTML_ATTRIBUTE_INLINE_STYLE);
        }
      }
    } // Second: All elements with `style=` attribute

  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }

  nodes = Array.prototype.slice.call(document.querySelectorAll("[style*=\"".concat(HTML_ATTRIBUTE_COOKIE_IDS, "\"]")));

  var _iterator2 = _createForOfIteratorHelper(nodes),
      _step2;

  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var _node = _step2.value;

      _node.setAttribute("style", transformInlineStyle(_node.getAttribute("style"), checker)[0]);
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
}

export { transformInlineStyleRules };
import _extends from "@babel/runtime/helpers/extends";
import _createForOfIteratorHelper from "@babel/runtime/helpers/createForOfIteratorHelper";
import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useState, useMemo } from "react";
import { commonInlineStyleBodyTeaching, commonInlineStyleBodyTeachingsSeparator, Cookie, useBlocker, useCommonTeachings, BlockerInlineStyles, BlockerBodyInlineStyles, BlockerGroupsInlineStyles, BlockerButton } from "../../..";

var BlockerBody = function BlockerBody() {
  var blocker = useBlocker();

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      areCookiesVisible = _useState2[0],
      setCookiesVisible = _useState2[1];

  var teachingsSeparatorActive = blocker.bodyDesign.teachingsSeparatorActive,
      _blocker$decision = blocker.decision,
      acceptAll = _blocker$decision.acceptAll,
      acceptIndividual = _blocker$decision.acceptIndividual,
      _blocker$texts = blocker.texts,
      blockerLoadButton = _blocker$texts.blockerLoadButton,
      blockerLinkShowMissing = _blocker$texts.blockerLinkShowMissing,
      blockerAcceptInfo = _blocker$texts.blockerAcceptInfo,
      cookies = blocker.blocker.cookies,
      consent = blocker.consent,
      groups = blocker.groups,
      onUnblock = blocker.onUnblock,
      productionNotice = blocker.productionNotice,
      close = blocker.i18n.close; // Calculate missing cookies

  var missing = useMemo(function () {
    var missing = [];
    var allConsentCookies = [];

    for (var _i = 0, _Object$values = Object.values(consent.groups); _i < _Object$values.length; _i++) {
      var _cookies = _Object$values[_i];
      allConsentCookies.push.apply(allConsentCookies, _toConsumableArray(_cookies));
    }

    var _iterator = _createForOfIteratorHelper(groups),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var items = _step.value.items;

        var _iterator2 = _createForOfIteratorHelper(items),
            _step2;

        try {
          for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
            var cookie = _step2.value;

            // Cookie is needed for this blocker? Yes, check if already consent...
            if (cookies.indexOf(cookie.id) > -1 && allConsentCookies.indexOf(cookie.id) === -1) {
              missing.push(cookie);
            }
          }
        } catch (err) {
          _iterator2.e(err);
        } finally {
          _iterator2.f();
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return missing;
  }, [groups, cookies, consent]);

  var _useCommonTeachings = useCommonTeachings({
    // Only enable USA notice when the blocked service is USA-relevant
    disableDataProcessingInUnsafeCountries: missing.map(function (_ref) {
      var ePrivacyUSA = _ref.ePrivacyUSA;
      return ePrivacyUSA;
    }).filter(Boolean).length === 0,
    // Not needed in blocker, as there is already a link with "Show all services which I need to consent to"
    disableListServicesNotice: true
  }),
      description = _useCommonTeachings.description,
      teachings = _useCommonTeachings.teachings;

  return h("div", BlockerBodyInlineStyles.bodyContainer(blocker), h("div", BlockerBodyInlineStyles.body(blocker), h("div", BlockerInlineStyles.topSide(blocker), h("div", BlockerBodyInlineStyles.description(blocker), h("span", {
    dangerouslySetInnerHTML: {
      __html: description.replace(/\n/gm, "<br />")
    }
  }), !!description && teachingsSeparatorActive && h("div", null, h("span", commonInlineStyleBodyTeachingsSeparator(blocker))), teachings.map(function (teaching) {
    return h("span", _extends({
      key: teaching
    }, commonInlineStyleBodyTeaching(blocker), {
      dangerouslySetInnerHTML: {
        __html: teaching
      }
    }));
  }), h("span", _extends({}, commonInlineStyleBodyTeaching(blocker), {
    dangerouslySetInnerHTML: {
      __html: blockerAcceptInfo
    }
  }))), h(BlockerButton, {
    type: acceptIndividual === "hide" ? "link" : acceptIndividual,
    inlineStyle: "showInfo",
    onClick: function onClick() {
      return setCookiesVisible(!areCookiesVisible);
    }
  }, areCookiesVisible ? close : blockerLinkShowMissing), areCookiesVisible && h("div", BlockerGroupsInlineStyles.cookieScroll(blocker), missing.map(function (cookie) {
    return h(Cookie, {
      key: cookie.id,
      cookie: cookie,
      checked: true,
      disabled: true
    });
  }))), h("div", BlockerInlineStyles.bottomSide(blocker), h(BlockerButton, {
    type: acceptAll === "hide" ? "button" : acceptAll,
    inlineStyle: "save",
    onClick: function onClick(e) {
      return onUnblock(e);
    },
    busyOnClick: true
  }, blockerLoadButton), productionNotice)));
};

export { BlockerBody };
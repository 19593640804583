function sortScores(scores) {
  scores.sort(function (a, b) {
    if (a.specificity > b.specificity) {
      return -1;
    }

    if (a.specificity < b.specificity) {
      return 1;
    }

    return 0;
  });
}

export { sortScores };
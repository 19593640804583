import { commonInlineStyleHeaderHeadline, useBlocker, BlockerHeaderInlineStyles } from "../../..";

var BlockerHeader = function BlockerHeader(_ref) {
  var closeIcon = _ref.closeIcon;
  var blocker = useBlocker();
  var name = blocker.blocker.name,
      blockerHeadline = blocker.texts.blockerHeadline;
  return h("div", BlockerHeaderInlineStyles.headerContainer(blocker), h("div", BlockerHeaderInlineStyles.header(blocker), h("div", commonInlineStyleHeaderHeadline(blocker, !!closeIcon), blockerHeadline.replace(/{{name}}/g, name)), closeIcon));
};

export { BlockerHeader };
import { useBlocker, useCommonFooterLinks, BlockerFooterInlineStyles } from "../../..";

var BlockerFooter = function BlockerFooter() {
  var blocker = useBlocker();

  var _useCommonFooterLinks = useCommonFooterLinks({
    putPoweredByLinkInRow: 1
  }),
      links = _useCommonFooterLinks.rows,
      renderLinks = _useCommonFooterLinks.render;

  return h("div", BlockerFooterInlineStyles.footerContainer(blocker), h("div", BlockerFooterInlineStyles.footer(blocker), renderLinks(links)));
};

export { BlockerFooter };
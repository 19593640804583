import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { getLastClickedConnectedCounter } from ".";
import { HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_BY, HTML_ATTRIBUTE_GOT_CLICKED, HTML_ATTRIBUTE_GOT_CLICKED_VALUE, HTML_ATTRIBUTE_GOT_CLICKED_VALUE_DO_NOT_USE_IN_NESTED, parents } from "..";
/**
 * Detect if a given unblocked node is the last clicked element so we can delegate the click
 * event to the unblocking mechanism. This allows us e.g. autoplay for YouTube.
 */

function detectLastClicked(node) {
  var connectedBlocker = node.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED);
  var consentId = node.getAttribute(HTML_ATTRIBUTE_BLOCKER_ID);
  var consentBy = node.getAttribute(HTML_ATTRIBUTE_BY);
  var lastClicked = getLastClickedConnectedCounter();
  var isLastClicked = "".concat(lastClicked) === connectedBlocker;

  if (isLastClicked) {
    node.setAttribute(HTML_ATTRIBUTE_GOT_CLICKED, HTML_ATTRIBUTE_GOT_CLICKED_VALUE);
  } else {
    // Check if there is a parent unblocked element which got clicked and we can
    // use it as our "click"-indicator. This allows us to delegate click elements to
    // the first unblocked, nested element.
    var _parents = parents(node, "[".concat(HTML_ATTRIBUTE_GOT_CLICKED, "=\"").concat(HTML_ATTRIBUTE_GOT_CLICKED_VALUE, "\"][").concat(HTML_ATTRIBUTE_BLOCKER_ID, "=\"").concat(consentId, "\"][").concat(HTML_ATTRIBUTE_BY, "=\"").concat(consentBy, "\"]")),
        _parents2 = _slicedToArray(_parents, 1),
        parentGotClicked = _parents2[0];

    if (parentGotClicked) {
      parentGotClicked.setAttribute(HTML_ATTRIBUTE_GOT_CLICKED, HTML_ATTRIBUTE_GOT_CLICKED_VALUE_DO_NOT_USE_IN_NESTED);
      isLastClicked = true;
    }
  }

  return isLastClicked;
}

export { detectLastClicked };
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useEffect, useState } from "react";
import { waitElementInViewport } from "../utils";

function useWaitElementInViewport(ref) {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      inViewport = _useState2[0],
      setInViewport = _useState2[1];

  useEffect(function () {
    if (ref.current) {
      waitElementInViewport(ref.current).then(function () {
        setInViewport(true);
      });
    }
  }, []);
  return inViewport;
}

export { useWaitElementInViewport };
/**
 * @see https://stackoverflow.com/a/7557433/5506547
 */
function isElementInViewport(el) {
  var partial = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : false;

  var _el$getBoundingClient = el.getBoundingClientRect(),
      top = _el$getBoundingClient.top,
      left = _el$getBoundingClient.left,
      bottom = _el$getBoundingClient.bottom,
      right = _el$getBoundingClient.right,
      height = _el$getBoundingClient.height,
      width = _el$getBoundingClient.width;

  var _window = window,
      innerWidth = _window.innerWidth,
      innerHeight = _window.innerHeight;

  if (partial) {
    var vertInView = top <= innerHeight && top + height >= 0;
    var horInView = left <= innerWidth && left + width >= 0;
    return vertInView && horInView;
  } else {
    var _document$documentEle = document.documentElement,
        clientHeight = _document$documentEle.clientHeight,
        clientWidth = _document$documentEle.clientWidth;
    return top >= 0 && left >= 0 && bottom <= (innerHeight || clientHeight) && right <= (innerWidth || clientWidth);
  }
}

export { isElementInViewport };
import { iterateRules } from ".";

function iterateStylesheets(element, scores, property) {
  var _document = document,
      styleSheets = _document.styleSheets;

  for (var styleSheetIdx in styleSheets) {
    var styleSheet = styleSheets[styleSheetIdx];
    var rules = void 0;

    try {
      rules = styleSheet.cssRules || styleSheet.rules;
    } catch (e) {
      // Rules could not be read, e.g. CORS policy (https://stackoverflow.com/a/49994161/5506547)
      continue;
    }

    if (!rules) {
      continue;
    }

    iterateRules(rules, element, scores, property);
  }
}

export { iterateStylesheets };
import _defineProperty from "@babel/runtime/helpers/defineProperty";
var MEMORIZE_JQUERY_EVENT_PROPERTY = "rcbJQueryEventListenerMemorize";
/**
 * We listen to the passed event and once that event got triggered, all event registrations
 * afterwards are automatically executed, too. This does not need another trigger again.
 *
 * You need to call this function as early as possible to catch all triggers.
 */

function memorizeJQueryEvent(doc, element, eventName) {
  var memorizeProp = "".concat(MEMORIZE_JQUERY_EVENT_PROPERTY, "_").concat(eventName);

  var _ref = doc.defaultView || doc.parentWindow,
      jQuery = _ref.jQuery;

  if (!jQuery) {
    return;
  }

  var event = jQuery.event,
      Event = jQuery.Event;

  if (!event || !Event || event[memorizeProp]) {
    return;
  }

  Object.assign(event, _defineProperty({}, memorizeProp, new Promise(function (resolve) {
    return jQuery(element).on(eventName, resolve);
  })));
}

export { memorizeJQueryEvent, MEMORIZE_JQUERY_EVENT_PROPERTY };
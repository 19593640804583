import { HTML_ATTRIBUTE_BLOCKER_CONNECTED, HTML_ATTRIBUTE_BLOCKER_CONNECTED_PRESERVED, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_BY, HTML_ATTRIBUTE_COOKIE_IDS, HTML_ATTRIBUTE_THUMBNAIL } from "..";

/**
 * Create visual content blocker container for a given node.
 */
function createVisualContainer(node, createBefore, visualParentMode) {
  var container = document.createElement("div");
  var style = container.style;
  var connectedCounter = node.getAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED_PRESERVED); // Connect blocker with blocked node

  container.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED, connectedCounter);
  container.className = "rcb-content-blocker";
  style.setProperty("max-height", "initial");
  style.setProperty("pointer-events", "all");
  node.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED, connectedCounter);
  createBefore.parentNode.insertBefore(container, createBefore); // Copy consent attributes so they are found via `findBlockedNodes`, too

  [HTML_ATTRIBUTE_BY, HTML_ATTRIBUTE_BLOCKER_ID, HTML_ATTRIBUTE_COOKIE_IDS].forEach(function (a) {
    if (node.hasAttribute(a)) {
      container.setAttribute(a, node.getAttribute(a));
    }
  }); // Connect a children selector with the content blocker above

  if (visualParentMode === "childrenSelector") {
    createBefore.setAttribute(HTML_ATTRIBUTE_BLOCKER_CONNECTED, connectedCounter);
  } // Find configured visual thumbnail


  var thumbnail;

  if (node.hasAttribute(HTML_ATTRIBUTE_THUMBNAIL)) {
    thumbnail = JSON.parse(node.getAttribute(HTML_ATTRIBUTE_THUMBNAIL));
  } else {
    var nestedThumbnailNode = node.querySelectorAll("[".concat(HTML_ATTRIBUTE_THUMBNAIL));

    if (nestedThumbnailNode.length > 0) {
      thumbnail = JSON.parse(nestedThumbnailNode[0].getAttribute(HTML_ATTRIBUTE_THUMBNAIL));
    }
  } // Hide our element and render content blocker


  (visualParentMode === "childrenSelector" ? createBefore : node).style.setProperty("display", "none", "important");
  return {
    container: container,
    thumbnail: thumbnail
  };
}

export { createVisualContainer };
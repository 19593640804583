/**
 * Attention: Never use this with `async/await` as it blocks the thread!
 */
function waitElementInViewport(container) {
  return new Promise(function (resolve) {
    if (window.IntersectionObserver) {
      new IntersectionObserver(function (entries, observer) {
        entries.forEach(function (_ref) {
          var isIntersecting = _ref.isIntersecting;

          if (isIntersecting) {
            resolve();
            observer.disconnect();
          }
        });
      }).observe(container);
    } else {
      resolve();
    }
  });
}

export { waitElementInViewport };
import _objectSpread from "@babel/runtime/helpers/objectSpread2";
import { calculateScore } from ".";
import { matchingSelector } from ".";

function iterateRules(rules, element, scoresPool, property) {
  for (var ruleIdx in rules) {
    var rule = rules[ruleIdx];

    if (!(rule instanceof CSSStyleRule)) {
      continue;
    }

    try {
      if (matchingSelector(element, rule.selectorText)) {
        var style = rule.style[property];

        if (style !== undefined && style !== "") {
          scoresPool.push(_objectSpread(_objectSpread({}, calculateScore(rule, scoresPool.length, property)), {}, {
            style: style
          }));
        }
      }
    } catch (e) {// Silence is golden.
    }
  }
}

export { iterateRules };